import React from "react";
import roadmap from "../assets/roadmap.png";
import "./dist/index.css";

function RoadMap() {
  return (
    <>
      <div className="road_map">
        <h1 className="headline">RoadMap</h1>

        <div className="image">
          <img src={roadmap} alt="" />
        </div>
      </div>
    </>
  );
}

export default RoadMap;
