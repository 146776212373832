import React from "react";

export const Faq: React.FC<any> = () => {
  let list: any = [
    {
      question: "what is the heart of Tweet Club?",
      answer:
        "The Tweet Club heart is a community of people who want to maximize the potential of their social media activity, especially on Twitter.",
    },
    {
      question: "what is the total supply of the Tweets?",
      answer:
        "A Total of 10,000 unique Tweets Club NFTs will be created.",
    },
    {
      question: "When the Tweets Club are released?",
      answer:
        "We want to give our community a fair chance to get their special tweet so we use a raffle system that gives you randomly a tweet.",
    },
    {
      question: "What will be the mint price?",
      answer:
        "Our Basics is to give everyone the ability to join our community, so we chose to set the mint price on (0. 1) ETH + gas and you can mint double tweets at the same time (2 tweets per wallet).",
    },
    {
      question: "Enough Tweets...  How do I get whitelisted?",
      answer:
        "To get whitelisted you need to join our discord server and to the mission the included there. also we plan to do some competitions and the winners will get prizes.",
    },
    {
      question: "OK, I got you so how do I mint my tweets?",
      answer:
        "First, you need to open a metamask wallet and buy some ETH via many exchanges (Binance, Bybit) then you need to deposit your ETH into the Metamask wallet and connect the wallet to our site when the minting time has come.",
    },
  ];
  return (
    <div className="faq">
      <div className="faq__title">Faq</div>
      <div className='faq__questions'>

          {list.map((item:any)=>{
              return (
                  <div className="faq__questions__item">
                      <div className="faq__questions__item__question">{item.question}</div>
                      <div className="faq__questions__item__answer">{item.answer}</div>
                  </div>
              )
          })}
      </div>
    </div>
  );
};

export default Faq;
