import React from 'react';
import comingSoon from '../assets/comingsoon.png';
import NavBar from './navbar';
import read_our_story from '../assets/button.svg';
import ImagesCarousel from './carousel';
const AboutUs = () => {
    return (
        <>
            <div className="about_us">
                <h1 className='headline'>About us</h1>

                <p>
                Tweet Club is a collection of 10,000 NFT's (non-fungible tokens),
the art is made from 50 hands made creation, by a well-known artist Eden Raymond. The vision of the project is to make a community of the art holders who is
Content creators and opinion leaders on Twitter that inspire and develop.
                </p>

                <img className='read_our_story' src={read_our_story} />

                <ImagesCarousel />
            </div>
        </>

    );
}

export default AboutUs;
