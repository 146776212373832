import React from 'react';
import comingSoon from '../assets/comingsoon.png';
import NavBar from './navbar';

const  Header = () =>  {
    return (
        <>
            <div className="header">
            <NavBar />
                <img className='header-image' src={comingSoon} alt="Coming soon..." />
            </div>
        </>

    );
}

export default Header;
