import React from "react";
import "./App.css";
import NavBar from "./components/navbar";
import Footer from "./components/footer/footer";
import Header from "./components/header";
import AboutUs from "./components/about_us";
import RoadMap from "./components/road_map";
import Faq from "./components/faq";
 
function App() {
  document.title = 'Tweet-Club NFT';

  return (
    <div className="App">
      <Header />
      <AboutUs />
      <RoadMap />
      <Faq/>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
