
import React from 'react';
import NavBar from './navbar';
import image1 from '../assets/13001.png';
import bird1 from '../assets/bird1.svg';
import bird2 from '../assets/bird2.svg';
import bird3 from '../assets/bird3.svg';

const ImagesCarousel = () => {
 
  
  return (
        <div className='carousel'>
          <img className='bird bird1' src={bird2} alt=""  />
          <img className='bird bird2' src={bird2} alt=""  />
          <img className='bird bird3' src={bird2} alt=""  />
      </div>

      );
}

      export default ImagesCarousel;
