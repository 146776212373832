import React from 'react';
// import './App.css';
import "./dist/index.css";

const  NavBar = () => {
    return (
        <div className="nav_bar">
            <div><a className='header_link' href="http://tweet-club.com">Tweet-Club</a></div>

            {/* <div> 
                <a className='header_mini_link mr-2' href="http://tweet-club.com">The Story</a>
                <a className='header_mini_link' href="http://tweet-club.com">Specials</a>
            </div> */}
        </div>
    );
}

export default NavBar;
